@tailwind base;

*,
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

canvas {
  user-select: none;
}

.marquee {
  position: relative;
  overflow: hidden;
  z-index: -1;
  top: -60vh;
  margin-top: -172px;
  --offset: 0vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
}
@media (max-width: 768px) {
  .marquee {
    display: none;
  }
}
.marquee .marquee-inner {
  white-space: nowrap;
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 5s linear infinite;
  animation-play-state: running;
}
.marquee .marquee-inner span {
  font-size: 10vw;
  padding: 0 2vw;
}

.shaderImage {
  height: 0;
  width: 100%;
  padding-bottom: 100%;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }
  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-white text-gray-900 dark:bg-black dark:text-gray-100;
    @apply min-h-screen;

    ::selection {
      @apply text-white bg-blue-600;
    }
  }

  @font-face {
    font-family: 'Respira-Black';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./fonts/Respira-Black.otf') format('opentype');
  }
  @font-face {
    font-family: 'OperatorMonoMediumRegular';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('./fonts/OperatorMonoMediumRegular.otf') format('opentype');
  }
  @font-face {
    font-family: 'SharpSansBold';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./fonts/SharpSansBold.otf') format('opentype');
  }
}
